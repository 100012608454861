import React, { FunctionComponent, useState, VoidFunctionComponent } from 'react'
import { Input as AntInput, Select } from 'antd'
import { Control, Controller } from 'react-hook-form'
import { InputType, MappedOption } from '../../../models'
import { colors } from '../../../constants'
import styled, { css } from 'styled-components'
import ReactPhoneInput from 'react-phone-number-input'

interface Props {
  control: Control<any>
  name: string
  placeholder: string
  error?: string | false | object
  Icon?: FunctionComponent
  type?: InputType
  containerClassName?: string
  errorClassName?: string
  label?: string
  options?: MappedOption[]
  changeHandler?: () => void
  loading?: boolean
  disabled?: boolean
  inputClassName?: string
  maxLength?: number
  id?: string
}

const Input: VoidFunctionComponent<Props> = ({
  type = InputType.TEXT,
  disabled,
  control,
  name,
  placeholder,
  error,
  Icon,
  containerClassName = '',
  inputClassName = '',
  errorClassName = '',
  label,
  options = [],
  maxLength,
  changeHandler = () => {},
  loading = false,
  id,
}) => {
  const [isFocused, setFocus] = useState(false)

  return (
    <Styles hasError={!!error} className={error ? errorClassName : containerClassName} >
      <Controller
        render={({ field }) => {
          const { onChange } = field
          switch (type) {
            case InputType.SELECT:
              return (
                <Select
                  data-testid={name}
                  placeholder={placeholder}
                  {...field}
                  onChange={onChange}
                  options={options}
                  optionFilterProp="label"
                  loading={loading}
                  disabled={disabled}
                  id={id}
                />
              )
            case InputType.PHONE:
              return (
                <ReactPhoneInput
                  control={control}
                  placeholder={placeholder}
                  {...field}
                  defaultCountry="FI"
                  onChange={onChange}
                  id={id}
                />
              )
            default:
              return (
                <AntInput
                  id={id}
                  maxLength={maxLength}
                  className={inputClassName}
                  onFocus={() => setFocus(true)}
                  onBlurCapture={() => setFocus(false)}
                  type={type}
                  prefix={!!Icon && !isFocused ? <Icon /> : <span />}
                  placeholder={placeholder}
                  {...field}
                  onChange={e => {
                    changeHandler && changeHandler()
                    onChange(e)
                  }}
                  disabled={disabled}
                />
              )
          }
        }}
        name={name}
        control={control}
      />
      {error && <span> {error} </span>}
    </Styles>
  )
}

export default Input

interface StyledProps {
  hasError?: boolean
  doesntHaveBorder?: boolean
  id?: string
}

const Styles = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  .error-message {
    margin-top: 2px;
    text-align: left;
  }
  textarea,
  input,
  select {
    color: ${colors.black};
    font-size: 16px;
    font-family: RightGrotesk-Regular;
    line-height: 22px;
    background-color: ${colors.white};
    border: ${({ doesntHaveBorder }: StyledProps): string =>
      doesntHaveBorder ? '0' : `1.5px solid ${colors.primary}`} !important;
    padding: 15px 10px !important;
    text-decoration: none;
    cursor: text;
    width: 100%;
    box-sizing: border-box;
    ::placeholder {
      color: ${colors.black};
      opacity: 0.5;
    }
    :focus {
      ::placeholder {
        color: ${colors.black};
        opacity: 0.7;
      }
      outline: none;
      border-radius: 2px;
    }
    ${({ hasError }: StyledProps): any =>
      hasError &&
      css`
        border: 1px solid ${colors.error};
        background-color: #ffdbd8;
      `};
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 7px 0;
    }
  }
  .PhoneInput {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    font-family: RightGrotesk-Regular;
    line-height: 22px;
    background-color: #ffffff;
    border: 1.5px solid #fc3d7e;
    padding: 0 10px;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: text;
    // width: 100%;
    box-sizing: border-box;
    // height: 100%;
  }

  .PhoneInputInput {
    color: #000000;
    font-size: 16px;
    font-family: RightGrotesk-Regular;
    background-color: #ffffff;
    border: 0 !important;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: text;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    min-width: 0;
  }

  .PhoneInputInput:focus {
    outline: 0;
  }

  .PhoneInputCountryIcon {
    width: calc(1em * 1.5);
    height: 1em;
  }

  .PhoneInputCountryIcon--square {
    width: 1em;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.8;
  }

  .PhoneInputInternationalIconGlobe {
    opacity: 0.65;
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
  }
  @media only screen and (max-width: 768px) {
    margin: 7px;
  }
  .PhoneInputCountrySelect {
    flex: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
  }
`
