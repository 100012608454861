import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

enum Language {
  FINNISH = 'fi',
  ENGLISH = 'en',
  SWEDISH = 'sv',
}

export const ChangeLanguage: React.FunctionComponent = () => {
  const { i18n } = useTranslation<string>()
  const history = useHistory()

  const changeLang = (lang: any) => {
    i18n.changeLanguage(lang)
    const path = history.location.pathname.split('/')
    const newLocale = path[path.length - 1]
    const newPath = history.location.pathname.replace(newLocale, lang)
    history.push(newPath)
  }
  return (
    <Container id="languageSelectMenu">
      <div className="language-wrapper">
        {Object.keys(Language).map(key => {
          let langKey = key as keyof typeof Language

          return (
            <button
              onClick={() => changeLang(Language[langKey])}
              className={
                i18n.language === Language[langKey] ? `select-language-button-selected` : `select-language-button`
              }
            >
              {Language[langKey]}
            </button>
          )
        })}
      </div>
    </Container>
  )
}

export default ChangeLanguage

const Container = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  .language-wrapper {
    display: flex;
    gap: 5px;
  }

  button:first-child:before {
    content: '';
  }
  button:before {
    content: '/';
    margin: 0 10px;
    color: #ffdbd8;
  }
  .select-language-button-selected,
  .select-language-button {
    font-weight: bold;
    background-color: transparent;
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
    font-family: 'RightGrotesk-SpatialDark', sans-serif;
    color: #ff598f;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.25s ease;
  }
  .select-language-button {
    color: #ffdbd8;
  }

  .select-language-button:focus {
    outline: 0;
  }

  .select-language-button:hover {
    color: #ff598f;
  }

  .select-language-button-selected:focus {
    outline: 0;
  }
`
