import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { getCodeRequestAsync } from './asyncActions'
import { Status } from 'models'
import { WebCodeGetResponse } from 'models'

export interface GetCodeState {
  is_fetching: boolean
  error: any
  status: Status
  data: WebCodeGetResponse
  code: string
}

const initialState: GetCodeState = {
  is_fetching: false,
  status: Status.IDLE,
  error: null,
  data: null,
  code: null,
}

export const getCode = createSlice({
  name: 'getCode',
  initialState,
  reducers: {
    reset: state => {
      state.status = Status.IDLE
      state.is_fetching = false
      state.error = null
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getCodeRequestAsync.pending, state => {
        state.status = Status.PENDING
      })
      .addCase(getCodeRequestAsync.fulfilled, (state, action) => {
        const { data }: any = action.payload
        if (data?.registered) {
          state.error = {
            message: 'codeIsRegistered',
          }
          state.status = Status.FAILED
        } else {
          state.status = Status.SUCCEEDED
          state.data = data
          state.error = null
        }
      })
      .addCase(getCodeRequestAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const seleckGetCodeStatus = (state: RootState): GetCodeState['status'] => state.getCode.status
export const selectGetCodeError = (state: RootState): GetCodeState['error'] => state.getCode.error
export const selectGetCodeData = (state: RootState): GetCodeState['data'] => state.getCode.data
export const selectCode = (state: RootState): GetCodeState['code'] => state.getCode.code

export default getCode.reducer
export const { reset, setCode } = getCode.actions
