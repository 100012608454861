import Text from './Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, FlexCenteredContainer } from 'constants/index'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const FooterMessages = () => {
  const [t] = useTranslation()

  return (
    <Container>
      <Text
        fontSize={calculateDynamicFont({ max: FontSize.FOURTEEN, min: FontSize.TWELVE })}
        fontFamily={FontFamily.BOLD}
        className="footer-title"
        fontWeight={FontWeight.SEVEN}
      >
        {t('didYouBuyTheMuseumCardAsGift')}
      </Text>
      <Text fontSize={calculateDynamicFont({ max: FontSize.FOURTEEN, min: FontSize.TWELVE })}>
        {t('weRecommedThatTheReceivers')}
      </Text>
    </Container>
  )
}
export default FooterMessages

const Container = styled(FlexCenteredContainer)`
  max-width: 381px;
  flex-direction: column;
  .footer-title{
    margin-top: 50px;
    margin-bottom 8px;  
  }
  @media (max-width: 700px) {
    .footer-title{
      margin-top: 40px;
      margin-bottom 8px;
    }
  }
`
