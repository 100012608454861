import { GeneralFormFieldType } from '../../models/forms/GeneralFormFields'
import { RegisterFormRequest } from 'models'
import { InputType } from '../../models'

export const RegisterFormFields: GeneralFormFieldType<RegisterFormRequest> = {
  firstname: {
    placeholder: 'firstName',
  },
  lastname: {
    placeholder: 'lastName',
  },
  birth_year: {
    placeholder: 'yearOfbirth',
  },
  address: {
    placeholder: 'streetAddress',
  },
  zip_code: {
    placeholder: 'zipCode',
  },
  city: {
    placeholder: 'town',
  },
  email: {
    placeholder: 'emailAddress',
  },
  phone: {
    type: InputType.PHONE,
    placeholder: 'phoneNumber',
  },
  password: {
    placeholder: 'password',
    type: InputType.PASSWORD,
  },
  password_confirmation: {
    placeholder: 'confirmPassword',
    type: InputType.PASSWORD,
  },
  coupon_code: {
    placeholder: 'campaingCodePlaceholder',
    hideInForm: true,
  },
}
