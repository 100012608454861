enum colors {
  black = '#000000',
  white = '#ffffff',
  primary = '#fc3d7e',
  error = '#ff0000',
  inactive = 'rgba(0, 0, 0, 0.25)',
  grey = '#a7adba',
  red = '#e80c0c',
  WHITE_GREY = '#F9FAFA',
  LIGHT_BLUE = '#93ACEC',
  RED = '#ff0000',
  WHITE = 'white',
  PALEVIOLETRED = 'palevioletred',
  NAVY_BLUE = '#000080',
  TRANSPARENT = 'transparent',
  FRENCH_ROSE = '#FF598F',
  ROYAL_BLUE = '#576DDD',
  ALICE_BLUE = '#F3F7FA',
  WHISPER = '#E5E5E5',
  TOLOPEA = '#312C3D',
  MEDIUM_AQUAMARINE = '#57DD9D',
  HELIOTROPE = '#C069FB',
  LIGHT_SALMON = '#FF9F78',
  SELECTIVE_YELLOW = '#FFB600',
  FLAMINGO = '#EB5757',
  BERMUDA = '#86CEC9',
  LASER_LEMON = '#FFF466',
  TRANSPARENT_TOLOPEA = 'rgba(49, 44, 61, 0.07)',
  GREY_CHATEAU = '#999C9E',
  AZURE = '#f6f7f7',
  DODGER_BLUE = '#1c89fe',
  DODGER_BLUE_LIGHTER = '#1ca1f2',
  FERN = '#4bc959',
  MARINER = '#4267b2',
  DARK_PURPLE = '#2C253D',
  LIGHT_PINK = '#FFDBD8',
  LIGHT_GREY = '#EDEDED',
  YELLOW = '#FFDD00',
}

export { colors }
