import Text from './Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, FlexCenteredContainer } from 'constants/index'
import RegisterIcon from 'assets/icons/RegisterIcon.png'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HeaderMessages = () => {
  const [t] = useTranslation()

  return (
    <Container>
      <img src={RegisterIcon} alt="register" />
      <Text
        fontWeight={FontWeight.EIGHT}
        className="title"
        fontFamily={FontFamily.EXTRABOLD}
        fontSize={calculateDynamicFont({ max: FontSize.TWENTY_EIGHT, min: FontSize.TWENTY })}
      >
        {t('registerAsAMuseumCard')}
      </Text>
      <Text
        textAlign="left"
        className="message"
        fontSize={calculateDynamicFont({ max: FontSize.TWENTY, min: FontSize.FOURTEEN })}
      >
        {t('registrationMessage')}
      </Text>
    </Container>
  )
}

export default HeaderMessages

const Container = styled(FlexCenteredContainer)`
  flex-direction: column;
  & > img {
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 40px;
  }
  .message {
    margin-bottom: 62px;
    white-space: pre-line;
  }
  @media (max-width: 700px) {
    & > img {
      margin-bottom: 13px;
    }
    .message {
      margin-bottom: 33px;
      white-space: pre-line;
    }
  }
`
