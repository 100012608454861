import styled from "styled-components"

export enum ResponsiveConstant {
  isDesktopOrLaptop = '(min-width: 701px)',
  isTabletOrMobile = '(max-width: 700px)',
}

export const isDesktopOrLaptop = () => {
  return window.matchMedia(ResponsiveConstant.isDesktopOrLaptop).matches
}

export const FlexCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`