export function getKeyByValue(object: any = {}, value: string) {
  return Object.keys(object).find(key => object[key] === value)
}
export const removeSpaces = (value: string): any => value.replace(/\s/g, '')

export const isNumeric = (val: string): boolean => {
  return /^-?\d+$/.test(val)
}

export const generateFieldUuid = (name: string, secondPart: any = 'field'): string => {
  return `${name}-${secondPart}`
}

export const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}
export const padStartCode = (value: string): string => value.padStart(12, '0')


export const htmlEntityDecode = (str: string): string => {
  if (typeof str === 'string') {
    str = str.replace('&nbsp;', ' ')
    str = str.replace(/\\n/g, '  ')
    str = str.replace(/<br>/g, '\n')
    str = str.replace(/&quot;/g, "''")
    str = str.replace(/&#228;/g, 'ä')
    str = str.replace(/&#226;/g, 'ö')
    str = str.replace(/&rdquo;/g, '"')
    str = str.replace(/&ndash;/g, '-')
    str = str.replace(//g, '€')
    str = str.replace(/&gt;/g, '>')
    str = str.replace('', '')
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec)
    })
  }
  return str
}