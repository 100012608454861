import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { webCouponCheck } from './asyncActions'
import { Status } from 'models'

export interface CheckCouponState {
  is_fetching: boolean
  data: any
  status: Status
  error: any
}

const initialState: CheckCouponState = {
  is_fetching: false,
  data: null,
  status: Status.IDLE,
  error: null,
}

export const checkCoupon = createSlice({
  name: 'checkCoupon',
  initialState,
  reducers: {
    reset: state => {
      state = initialState
    },
  },

  extraReducers: builder => {
    builder
      .addCase(webCouponCheck.pending, state => {
        state.status = Status.PENDING
      })
      .addCase(webCouponCheck.fulfilled, (state, action) => {
        state.status = Status.SUCCEEDED
        state.data = action.payload
      })
      .addCase(webCouponCheck.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const seleckCheckCodeStatus = (state: RootState): CheckCouponState['status'] => state.checkCoupon.status
export const selectCheckCodeData = (state: RootState): CheckCouponState => state.checkCoupon.data
export const selectCheckCodeError = (state: RootState): CheckCouponState['error'] => state.checkCoupon.error
export default checkCoupon.reducer
export const { reset } = checkCoupon.actions
