//TODO, need to add type for body
import { createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const webUserCreate = createAsyncThunk<void, any, { rejectValue: string }>(
  '/web/user/create',
  async (body, { rejectWithValue }) => {
    const lang = localStorage.getItem('i18nextLng')
    try {
      return await WebService.webUserCreate(body, lang)
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
