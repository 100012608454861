import React, { useState, VoidFunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spin } from 'antd'
import Input from '../UiKit/Input'
import { RegisterFormFields } from './RegisterFormFields'
import { RegisterFormRequest, Status } from '../../models'
import { yupResolver } from '@hookform/resolvers/yup'
import registerSchema from '../../models/schemas/registerSchema'
import { useTranslation } from 'react-i18next'
import Button from '../UiKit/Button'
import DoubleInputContainer from '../UiKit/Input/DoubleInputContiner'
import CheckBoxForm from './CheckBoxForm'
import { CheckBoxRequest } from '../../models'
import { webCouponCheck } from 'store/CouponCheck/asyncActions'
import { webUserCreate } from 'store/register/asyncActions'
import { RegisterRequest } from 'models'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { selectCode } from 'store/getCode'
import { selectCheckCodeError } from 'store/CouponCheck'
import styled from 'styled-components'
import { FontFamily, FontSize, FontWeight, colors } from 'constants/index'
import { generateFieldUuid, padStartCode } from 'utils/functions'
import { seleckRegisterStatus } from 'store/register'

const RegisterForm: VoidFunctionComponent<{}> = () => {
  const { t, i18n } = useTranslation<string>()
  const [checkboxes, setCheckBoxes] = useState<CheckBoxRequest>({
    terms: false,
    newsletter: false,
    campaign: false,
  })
  const code = useAppSelector(selectCode)
  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(registerSchema) })
  const checkCodeError = useAppSelector(selectCheckCodeError)
  const registerFields = Object.entries(RegisterFormFields)
  const status = useAppSelector(seleckRegisterStatus)

  const onSubmit = (values: RegisterFormRequest) => {
    const newObj: RegisterRequest = {
      ...values,
      newsletter: checkboxes.newsletter,
      contact_language: i18n.language,
      plasticCard: true,
      code: padStartCode(code),
    }
    if (checkboxes.campaign === false) {
      delete newObj.coupon_code
    }
    if (values.coupon_code) {
      dispatch(webCouponCheck(values.coupon_code)).then(() => {
        if (values.password === values.password_confirmation) {
          dispatch(webUserCreate(newObj))
        }
      })
    } else {
      if (values.password === values.password_confirmation) {
        dispatch(webUserCreate(newObj))
      }
    }
  }

  const handleChange = (e: any, value: boolean) => {
    setCheckBoxes(prevState => ({
      ...prevState,
      [e]: value,
    }))
  }

  return (
    <Form name="register" noValidate>
      {registerFields.map((field, index) => {
        if (index % 2 === 0 && index < 10) {
          return (
            <React.Fragment key={index}>
              {index === 8 && <p>{t('createPasswordForMuseumCard')}</p>}
              <DoubleInputContainer>
                <Input
                  containerClassName="input-code"
                  errorClassName="ant-form-item-has-error"
                  control={control}
                  name={field[0]}
                  placeholder={t(field[1].placeholder)}
                  error={t(errors[field[0]]?.message)}
                  type={field[1].type}
                  id={generateFieldUuid(field[0], index)}
                />
                <Input
                  containerClassName="input-code"
                  errorClassName="ant-form-item-has-error"
                  control={control}
                  name={registerFields[index + 1][0]}
                  placeholder={t(registerFields[index + 1][1].placeholder)}
                  error={t(errors[registerFields[index + 1][0]]?.message)}
                  type={registerFields[index + 1][1].type}
                  id={generateFieldUuid(registerFields[index + 1][0], index)}
                />
              </DoubleInputContainer>
            </React.Fragment>
          )
        } else return null
      })}
      <CheckBoxForm handleChange={handleChange} checkboxes={checkboxes} />
      {!!checkboxes.campaign && (
        <Form.Item>
          <Input
            containerClassName="input-code"
            id={generateFieldUuid('coupon_code')}
            errorClassName="ant-form-item-has-error"
            control={control}
            name={'coupon_code'}
            placeholder={t(registerFields[10][1].placeholder)}
            error={errors[registerFields[10][0]]?.message}
          />
          {checkCodeError?.errors?.coupon_code?.map((item: string) => {
            return <Error>{item}</Error>
          })}
        </Form.Item>
      )}
      <Form.Item>
        <Button
          disabled={!checkboxes.terms || status === Status.PENDING}
          className="fourteen wide computer twelve wide mobile centered column alignText"
          style={{ textAlign: 'center', marginTop: 20 }}
          type="submit"
          onClick={handleSubmit(form => onSubmit(form))}
          id={generateFieldUuid('continue')}
        >
          {status === Status.PENDING ? <SpineStyled size="small" /> : t('continue').toUpperCase()}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RegisterForm

const Error = styled.div`
  color: ${colors.RED};
  font-weight: ${FontWeight.SEVEN};
  font-family: ${FontFamily.NORMAL};
  font-size: ${FontSize.TWELVE};
`

const SpineStyled = styled(Spin)`
  .ant-spin-dot {
    width: 45px !important;
    height: 45px !important;
  }
  .ant-spin-dot-item {
    width: 25px !important;
    height: 25px !important;
    background-color: ${colors.ALICE_BLUE} !important;
  }
`
