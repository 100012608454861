import { createGlobalStyle } from 'styled-components'
import RightGroteskRegular from './RightGroteskWideRegular.ttf'
import RightGroteskBold from './RightGroteskWideBold.ttf'
import RightGroteskEXTRABold from './RightGroteskSpatialDark.ttf'
import RightGroteskLight from './RightGroteskSpatialLight.ttf'

export default createGlobalStyle`   
    html, body { 
      height: 100%; 
      width: 100%;
    }

    @font-face {
      font-family: 'RightGrotesk-Regular';
      src: url('${RightGroteskRegular}') format('opentype');
    }
  
    @font-face {
      font-family: 'RightGrotesk-bold';
      src: url('${RightGroteskBold}') format('opentype');
      font-weight: 700;
    }
      
    @font-face {
      font-family: 'RightGrotesk-extra-bold';
      src: url('${RightGroteskEXTRABold}') format('opentype');
      font-weight: 800
    }
  
    @font-face {
      font-family: 'RightGrotesk-light';
      src: url('${RightGroteskLight}') format('opentype');
    }
`
