import styled from 'styled-components'
import WarningIcon from 'assets/icons/icon-info.png'
import { useTranslation } from 'react-i18next'
import Text from './Text'
import { FontWeight, FontFamily, FontSize, calculateDynamicFont, colors, FlexCenteredContainer } from 'constants/index'
import { generateFieldUuid } from 'utils/functions'

const Warning = () => {
  const [t] = useTranslation()
  return (
    <WarningWrapper>
      <img src={WarningIcon} alt="warning" />
      <WarningMessages>
        <Text
          textAlign="left"
          fontSize={calculateDynamicFont({ max: FontSize.FOURTEEN, min: FontSize.TWELVE })}
          fontFamily={FontFamily.BOLD}
          fontWeight={FontWeight.SEVEN}
          width="fit-content"
        >
          {t('registerWarningMessage')}
        </Text>
        <Text
          textAlign="left"
          width="fit-content"
          id={generateFieldUuid('registerWarningSubMessage')}
          fontSize={FontSize.TWELVE}
          color={colors.FRENCH_ROSE}
          fontWeight={FontWeight.BOLD}
          onClick={() => window.open('https://museot.fi/lahjansaajalle')}
        >
          {t('registerWarningSubMessage')}
        </Text>
      </WarningMessages>
    </WarningWrapper>
  )
}

export default Warning

const WarningWrapper = styled(FlexCenteredContainer)`
  gap: 18px;
  margin-bottom: 72px;
  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: 40px;
    gap: 7px;
    div {
      text-align: center;
    }
  }
`

const WarningMessages = styled(FlexCenteredContainer)`
  flex-direction: column;
  gap: 5px;
  > div:last-child {
    cursor: pointer;
  }
`
