// eslint-disable-line @typescript-eslint/no-unused-vars
import { VoidFunctionComponent } from 'react'
import { CheckBoxRequest } from '../../models/services/CheckBoxRequest'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CheckBox from '../UiKit/CheckBox'
import { colors } from '../../constants/colors'
import Text from '../UiKit/Text'
import { generateFieldUuid } from 'utils/functions'

interface Props {
  isSubmitting?: boolean
  handleChange: (e: any, value: boolean) => void
  checkboxes: CheckBoxRequest
}

const CheckBoxForm: VoidFunctionComponent<Props> = ({ isSubmitting, handleChange, checkboxes }) => {
  const { t } = useTranslation<string>()
  // const couponCode = useWatch({
  //   control,
  //   name: 'coupon_code',
  // })

  // useEffect(() => {
  //   reset()
  // }, [checkboxes.campaign])

  return (
    <Container id="checkBox">
      <CheckBox
        className="checkbox-distance"
        isChecked={!!checkboxes.terms}
        id={generateFieldUuid('termsAndConditions')}
        onToggle={() => handleChange('terms', !checkboxes.terms)}
      >
        <Text className={!checkboxes.terms ? 'text-decoration-error' : 'text-decoration'}>
          {t('IHaveReadAndAccept')}

          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration"
            id={generateFieldUuid('termsAndConditionsLink')}
            href={'http://www.museot.fi/kayttoehdot'}
          >
            <Text className="hyperlink-text">{t('termsAndConditions')}</Text>
          </a>
          {t('termsAndConditions2')}
        </Text>
      </CheckBox>
      <CheckBox
        className="checkbox-distance"
        id={generateFieldUuid('newsletter')}
        isChecked={!!checkboxes.newsletter}
        onToggle={() => handleChange('newsletter', !checkboxes.newsletter)}
      >
        <Text>{t('receiveTheNewsletter')}</Text>
      </CheckBox>
      <CheckBox
        className="checkbox-distance"
        id={generateFieldUuid('campaign')}
        isChecked={!!checkboxes.campaign}
        onToggle={() => handleChange('campaign', !checkboxes.campaign)}
      >
        <Text>{t('iHaveCampaignCode')}</Text>
      </CheckBox>
    </Container>
  )
}

export default CheckBoxForm
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px 0;
  .checkbox-distance {
    margin: 5px;
  }
  .text-decoration {
    text-decoration: none;
  }
  .text-decoration-error {
    color: ${colors.red};
    text-decoration: none;
  }
  .hyperlink-text {
    color: #fc3d7e;
    font-family: RightGrotesk-bold;
  }
  .checkbox-distance {
    margin: 5px;
  }
`
