import { FunctionComponent } from 'react'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'

const Toast: FunctionComponent = () => {
  return <StyledContainer position="top-center" hideProgressBar />
}

const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    @media (min-width: 700px) {
      width: 80%;
      margin-top: 10em;
    }
  }
  .Toastify__toast-body {
    font-family: RightGrotesk-Regular;
    font-size: 18px;
    font-weight: 800;
    color: black;
  }
`
export default Toast
