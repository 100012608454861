import { colors, FlexCenteredContainer } from 'constants/index'
import styled from 'styled-components'
import Warning from './warning'
import FooterMessages from './footerMessage'
import HeaderMessages from './headerMessages'
import Login from './login'
import ChangeLanguage from './ChangeLanguage'
import { useEffect } from 'react'
import { ScrollToTop } from 'utils/functions'

const Home = () => {
  useEffect(() => {
    ScrollToTop()
  }, [])

  return (
    <Container>
      <BodyWrapper>
        <ChangeLanguage />
        <HeaderMessages />
        <Warning />
        <Login />
        <FooterMessages />
      </BodyWrapper>
    </Container>
  )
}
export default Home

const Container = styled(FlexCenteredContainer)`
  width: 100%;
  height: 100%;
  background: ${colors.DARK_PURPLE};
  font-family: 'RightGrotesk-Regular' !important;
  font-feature-settings: 'ss01' on;
  padding: 50px 0px 130px;
  @media (max-width: 700px) {
    padding: 32px 0px 45px;
  }
`
const BodyWrapper = styled(FlexCenteredContainer)`
  flex-direction: column;
  padding: 67px 67px 57px;
  background: ${colors.white};
  border-radius: 16px;
  max-width: 593px;
  width: 100%;
  position: relative;
  @media (max-width: 700px) {
    padding: 44px 22px 41px;
    margin: 0px 23px;
    label {
      height: fit-content;
    }
  }
`
