/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class MiscService {

    /**
     * Get museum tokens
     * @returns string Success
     * @throws ApiError
     */
    public static async miscMuseumTokens(): Promise<Record<string, string>> {
        const result = await __request({
            method: 'PUT',
            path: `/misc/museum/tokens`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get GisPosition report
     * @param type Type of the report
     * @param reportDate Date of the report in `Y_m`(for monthly) and `Y_m_d`(for daily) formats only, if is not specified request will return last report of provided type
     * @returns any Ok
     * @throws ApiError
     */
    public static async miscGisPositionReport(
        type: string,
        reportDate?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/misc/gis-position/report`,
            query: {
                'type': type,
                'report_date': reportDate,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden - Access denied`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * User do logout
     * @returns any Ok
     * @throws ApiError
     */
    public static async miscUserLogout(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/misc/user/logout`,
        });
        return result.body;
    }

}