import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import registerReducer from './register'
import getCodeReducer from './getCode'
import couponCheckReducer from './CouponCheck'

export const store = configureStore({
  reducer: {
    register: registerReducer,
    getCode: getCodeReducer,
    checkCoupon: couponCheckReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
