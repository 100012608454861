import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { FontSize, FontWeight, FontFamily } from 'constants/fonts'
import { colors } from 'constants/colors'
import { useTranslation } from 'react-i18next'
import { getKeyByValue } from 'utils/functions'
import { Languages } from 'constants/language'

interface ITextStyleParams {
  color?: colors
  onClick?: () => void
  fontSize?: FontSize | string
  fontFamily?: string
  fontWeight?: FontWeight
  wordWrap?: string
  whiteSpace?: string
  textAlign?: 'center' | 'left' | 'right'
  children: ReactNode
  width?: string
  display?: string
  height?: string
  className?: string
  autoTranslate?: boolean
  id?: string
}

const Text: FunctionComponent<ITextStyleParams> = ({
  color,
  fontSize,
  fontFamily,
  onClick,
  width = '100%',
  height = 'fit-content',
  display = 'flex',
  fontWeight = FontWeight.NORMAL,
  textAlign = 'center',
  wordWrap = 'break-word',
  whiteSpace = 'normal',
  autoTranslate = true,
  children,
  className = '',
  id,
}: ITextStyleParams) => {
  const { i18n } = useTranslation()
  function findTranslation(text: ReactNode) {
    if (i18n) {
      const doc: {
        [key: string]: { [key: string]: { [key: string]: string } }
      } = {}
      const translation = Object.values(Languages)
        .map((lang: string) => {
          doc[lang] = i18n.getDataByLanguage(lang)
          const key = doc[lang] ? getKeyByValue(doc[lang]['translation'], text as string) : undefined
          if (key) {
            const possibleTranslation = i18n.t(key)
            return possibleTranslation
          } else {
            return ''
          }
        })
        .find((item: string) => item !== '')
      if (translation) {
        return translation
      } else {
        return text
      }
    }
  }

  return (
    <StyledDiv
      onClick={onClick}
      className={className}
      color={color}
      width={width}
      height={height}
      display={display}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      textAlign={textAlign}
      wordWrap={wordWrap}
      whiteSpace={whiteSpace}
      id={id}
    >
      {autoTranslate ? findTranslation(children) : children}
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  color: ${(props: ITextStyleParams) => props.color};
  font-size: ${(props: ITextStyleParams) => props.fontSize};
  font-weight: ${(props: ITextStyleParams) => props.fontWeight};
  word-wrap: ${(props: ITextStyleParams) => props.wordWrap};
  white-space: ${(props: ITextStyleParams) => props.whiteSpace};
  width: ${(props: ITextStyleParams) => props.width};
  height: ${(props: ITextStyleParams) => props.height};
  justify-content: ${(props: ITextStyleParams) => props.textAlign};
  text-align: ${(props: ITextStyleParams) => props.textAlign};
  display: ${(props: ITextStyleParams) => props.display};
  font-family: ${(props: ITextStyleParams) => (props.fontFamily ? props.fontFamily : FontFamily.NORMAL)};
  align-items: center;
  line-height: 1.2;
`

export default Text
