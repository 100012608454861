import { createAsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'
import { renderLang } from 'utils'

export const getCodeRequestAsync = createAsyncThunk<number, number, { rejectValue: string }>(
  '/web/code/codeQueryParam',
  async (body, { rejectWithValue }) => {
    try {
      return await WebService.webCodeGet(body, renderLang())
    } catch (e) {
      if (e.status === 404) {
        const message: any = { message: 'cardNotFound' }
        return rejectWithValue(message)
      }
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
