import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { isMobile } from 'react-device-detect'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Button from './Buttons/Button'
import { ObjectShape } from 'yup/lib/object'
import Input from './Input'
import styled from 'styled-components'
import { FontFamily, FontSize, FontWeight } from 'constants/index'
import { colors } from 'constants/colors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getCodeRequestAsync } from 'store/getCode/asyncActions'
import { reset, seleckGetCodeStatus, selectGetCodeData, selectGetCodeError } from 'store/getCode'
import { useHistory } from 'react-router'
import { Status } from 'models'
import { setCode } from '../../store/getCode'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Toast from '../UiKit/Toast'
import { isNumeric, removeSpaces, generateFieldUuid } from 'utils/functions'
import { cardNumberPattern } from 'utils'

function ShowError({ errors, getCodeError, t }: any) {
  if (errors?.code) {
    return toast.error(t(errors.code?.message))
  }
  if (getCodeError?.message && typeof getCodeError?.message === 'object') {
    return toast.error(getCodeError.code?.message)
  }
  if (getCodeError?.message) {
    return toast.error(t(getCodeError?.message))
  }
  return null
}

const inputSchema = (t: TFunction): ObjectSchema<ObjectShape> =>
  Yup.object().shape({
    code: Yup.string().test('length-check', 'cardLength', value => value && value.length === 14),
  })

export const Login = () => {
  const [t] = useTranslation<string>()
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<any>({
    defaultValues: { code: '' },
    resolver: yupResolver(inputSchema(t)),
  })
  const dispatch = useAppDispatch()
  const getCodeData = useAppSelector(selectGetCodeData)
  const getCodeError = useAppSelector(selectGetCodeError)
  const getCodeStatus = useAppSelector(seleckGetCodeStatus)
  const [val, setVal] = useState<string>('')
  const history = useHistory()

  useEffect(() => {
    if (getCodeData && !getCodeData?.registered && getCodeStatus === Status.SUCCEEDED) {
      dispatch(setCode(getCodeData.code.toString()))
      history.push('/register')
      dispatch(reset())
    }
  }, [getCodeData, history, getCodeStatus, dispatch])

  useEffect(() => {
    ShowError({ errors, getCodeError, t })
    if (getCodeError) dispatch(reset())
  }, [errors, getCodeError, dispatch, t])

  const onSubmit = handleSubmit(async ({ code }: { code: any }) => {
    if (code) {
      if (getCodeData || !!getCodeError) {
        await reset()
      }
      dispatch(getCodeRequestAsync(removeSpaces(code)))
    }
  })

  return (
    <Container>
      <InputContainer
        type={isMobile ? 'tel' : 'text'}
        placeholder={t('code')}
        maxLength={14}
        value={val}
        id={generateFieldUuid('code')}
        {...register('code')}
        onChange={e => {
          if (isNumeric(removeSpaces(e.target.value))) {
            e.target.value = cardNumberPattern(e.target.value)
            setVal(e.target.value)
            setValue('code', e.target.value)
          }
          if (!e.target.value.length) {
            setVal('')
            setValue('code', '')
          }
          if (!e.target.value.length) {
            setVal('')
            setValue('code', '')
          }
        }}
      />
      <BtnContainer
        onClick={onSubmit}
        fontSize={FontSize.SIXTEEN}
        fontFamily={FontFamily.EXTRABOLD}
        fontWeight={FontWeight.EIGHT}
        text={t('submit')}
        id={generateFieldUuid('submit')}
      />
      <Toast />
    </Container>
  )
}

export default Login

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const InputContainer = styled(Input)`
  padding: 0px;
  input {
    box-shadow: none;
    border: 1px solid ${colors.LIGHT_BLUE};
  }
`
const BtnContainer = styled(Button)`
  height: 60px;
  border: none;
  max-width: 307px;
  width: 100%;
  align-self: center;
  border-radius: 30px;
  margin-top: 40px;
  @media (max-width: 700px) {
    max-width: 207px;
    margin-top: 24px;
  }
`
// const Error = styled.div`
//   color: ${colors.RED};
//   font-weight: ${FontWeight.SEVEN};
//   font-family: ${FontFamily.NORMAL};
//   font-size: ${FontSize.TWELVE};
// `
