import { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from 'Components/Home'
import { Register } from 'Components/Register'
import ThankYou from 'Components/ThankYou'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import { renderLang } from '../utils'

const Routes: React.FunctionComponent = () => {
  const { ready } = useTranslation()
  const [, i18n] = useTranslation()

  const supportedLanguages = ['fi', 'en', 'sv']
  const returnLoader = () => (
    <div className="spin-container">
      <Spin size="large" className="spin" />
    </div>
  )

  const renderRoute = (Component: any, props: any) => {
    const lang = props.match.params.lang
    const path = props.match.path
    if (!lang) {
      return <Redirect to={`${path}/${renderLang()}`} />
    } else {
      if (supportedLanguages.includes(lang)) {
        if (lang !== localStorage.getItem('i18nextLng')) {
          localStorage.setItem('i18nextLng', lang)
          i18n.changeLanguage(lang)
        }
        return <Component />
      } else {
        return <Redirect to={`/${renderLang()}`} />
      }
    }
  }

  return (
    <>
      {ready ? (
        <Suspense fallback={returnLoader()}>
          <Switch>
            <Route exact path="/">
              <Redirect to={`/${renderLang()}`} />
            </Route>
            <Route
              exact
              path={['/register', '/register/:lang']}
              render={(props: any) => renderRoute(Register, props)}
            />
            <Route
              exact
              path={['/thank-you', '/thank-you/:lang']}
              render={(props: any) => renderRoute(ThankYou, props)}
            />
            <Route exact path={'/:lang'} render={(props: any) => renderRoute(Login, props)} />
          </Switch>
        </Suspense>
      ) : (
        returnLoader()
      )}
    </>
  )
}

export default Routes
