import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RegisterForm from './RegisterForm'
import { seleckRegisterStatus, selectErrorData } from 'store/register'
import { useAppSelector } from 'store/hooks'
import Return from './Return'
import Header from '../UiKit/Header'
import { Status } from 'models'
import { useHistory } from 'react-router-dom'
import ErrorMessageForm from './ErrorMessage'
import { ScrollToTop } from 'utils/functions'

export const Register: React.FunctionComponent = () => {
  const { t } = useTranslation<string>()
  const status = useAppSelector(seleckRegisterStatus)
  const history = useHistory()
  const errorData = useAppSelector(selectErrorData)

  useEffect(() => {
    ScrollToTop()
  }, [])

  useEffect(() => {
    if (status === Status.SUCCEEDED) {
      history.push('/thank-you')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, history])

  return (
    <Container>
      <div className="row">
        <div className="main-cointainer area">
          <div className="return-button">
            <Return />
          </div>
          <div className="margin-1 aligned-container" id="contentBox">
            <div id="codeValidationForm" className="ui transition visible first page">
              <Header title={t('registerAsAMuseumCard')} />
              <p className="text-line-height">&nbsp;</p>
              <div className="ui center aligned segment central" id="summaryText">
                <p className="alignText delivery-information-text">{t('fillInTheInformationForTheDelivery')} </p>
                {status === Status.FAILED && (
                  <div className="error-message-container">
                    <ErrorMessageForm errorMessage={errorData} />
                  </div>
                )}
                <RegisterForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
  .aligned-container {
    margin: 1rem;
    padding: 0 3rem 2rem 3rem;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .row {
    background-color: #f7f7f7;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .return-button {
    text-align: left;
    margin: 20px 15px;
  }
  .error-message-container {
    padding-bottom: 1rem;
  }
  .delivery-information-text {
    padding: 0 2rem;
    margin: 1em 0;
    @media only screen and (max-width: 768px) {
      padding: 0 !important;
    }
  }
`
