import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit'
import { WebService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const webCouponCheck: AsyncThunk<any, any, { rejectValue: string }> = createAsyncThunk(
  '/web/coupon/check',
  async (coupon_code, { rejectWithValue }) => {
    try {
      return await WebService.webCouponCheck({ coupon_code, from: 'registration' })
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
