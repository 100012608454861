import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors } from 'constants/colors'
import { FontSize } from 'constants/fonts'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IInputParams {
  control?: Control<any>
  label?: string
  name?: string
  defaultValue?: string
  backgroundColor?: colors
  onChange?: (e: { target: { value: string } }) => void
  multiline?: boolean
  className?: string
  readOnly?: boolean
  placeholder?: string
  type?: string
  maxLength?: number
  value?: string
  id?: string
}

const Input: FunctionComponent<IInputParams> = ({
  // multiline = false,
  defaultValue,
  backgroundColor = colors.WHITE,
  onChange = e => {},
  readOnly = false,
  placeholder = '',
  className = '',
  control,
  type = 'text',
  name,
  maxLength,
  value = '',
  id,
}: IInputParams) => {
  const { t } = useTranslation()

  return (
    <div className={`vertical justify-content-start input ${className}`}>
      <div className={'item justify-content-start flex-none'}>
        <StyledLabel>{t(name)}</StyledLabel>
      </div>
      <div className={'item'}>
        {control ? (
          <Controller
            render={({ field }) => {
              const { onChange } = field
              return (
                <StyledTextInput
                  maxLength={maxLength}
                  readOnly={readOnly}
                  backgroundColor={backgroundColor}
                  placeholder={placeholder}
                  role={'input-field'}
                  defaultValue={defaultValue}
                  {...field}
                  type={type}
                  control={control}
                  disabled={readOnly}
                  onChange={onChange}
                  id={id}
                />
              )
            }}
            name={name}
            control={control}
          />
        ) : (
          <StyledTextInput
            maxLength={maxLength}
            readOnly={readOnly}
            backgroundColor={backgroundColor}
            placeholder={placeholder}
            role={'input-field'}
            defaultValue={defaultValue}
            disabled={readOnly}
            onChange={onChange}
            type={type}
            value={value}
            id={id}
          />
        )}
      </div>
    </div>
  )
}

export default Input

const StyledTextInput = styled.input<IInputParams>`
  background-color: ${(props: IInputParams) => props.backgroundColor};
  width: 100%;
  height: 72px;
  padding: 12px 20px;
  display: inline-block;
  border-radius: 10px;
  border: none;
  box-sizing: border-box;
  color: ${colors.TOLOPEA};
  line-height: 140%;
  font-size: ${FontSize.TWENTY};
  outline: none;
  box-shadow: inset 0px 1px 2px ${colors.TRANSPARENT_TOLOPEA};
  font-size: 18px;
  @media (max-width: 700px) {
    font-size: 16px;
  }
  //
`

// const StyledTextArea = styled.textarea<IInputParams>`
//   background-color: ${(props: IInputParams) => props.backgroundColor};
//   width: 100%;
//   padding: 12px 20px;
//   display: inline-block;
//   border-radius: 10px;
//   border: none;
//   box-sizing: border-box;
//   color: ${Colors.TOLOPEA};
//   line-height: 140%;
//   font-size: ${FontSize.TWENTY};
//   outline: none;
//   box-shadow: inset 0px 1px 2px ${Colors.TRANSPARENT_TOLOPEA};
// `

const StyledLabel = styled.label`
  color: #2c253d;
  font-size: 18px;
  line-height: 24px;
  height: 24px;
  font-weight: 700;
  margin-bottom: 7px;
  display: flex;
  @media (max-width: 700px) {
    font-size: 16px;
  }
`
