import styled, { css } from 'styled-components'
import { colors } from '../../../constants/colors'

interface StyledProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isDisabled?: boolean
}

interface Props extends StyledProps {
  primary?: boolean
  id?: string
}

const Button = styled.button<Props>`
  /* This renders the buttons above... Edit me! */
  background-color: #fc3d7e;
  font-family: RightGrotesk-SpatialDark, sans-serif;
  font-weight: 800;
  font-style: normal;
  cursor: pointer;
  border-radius: 3rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #ffffff;
  opacity: ${(props: StyledProps): string => (props.disabled ? '0.5' : '1')};
  border: 0;
  height: 4rem;
  width: 100%;
  &:hover {
    background-color: ${(props: StyledProps): string => (props.disabled ? colors.primary : colors.inactive)};
    color: ${(props: StyledProps): string => (props.disabled ? 'white' : 'black')};
  }

  /* The GitHub button is a primary button
   * edit this to target it specifically! */
  ${(props: Props): any =>
    props.primary &&
    css`
      background: white;
      color: palevioletred;
    `}
`

export default Button
