import React from 'react'
import Text from '../UiKit/Text'
import ErrorMessage from '../UiKit/ErrorMessage'
import { useTranslation } from 'react-i18next'

interface Props {
  errorMessage: any
  messageCode?: string
}
const ErrorMessageForm: React.FunctionComponent<Props> = ({ errorMessage, messageCode }) => {
  const [t] = useTranslation<string>()

  if (errorMessage?.errors) {
    return (
      <ErrorMessage>
        {Object.keys(errorMessage?.errors).map(label => {
          return <p>{errorMessage?.errors[label][0]}</p>
        })}
      </ErrorMessage>
    )
  } else {
    return (
      <ErrorMessage>
        <Text>{t('cardNotFound')}</Text>
      </ErrorMessage>
    )
  }
}

export default ErrorMessageForm
